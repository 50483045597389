import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

export type NewsletterFormData = {
  marketing_authorization: boolean
  brands_preferences?: string[]
  channels_preferences?: string[]
  prefix?: string
  phoneNumber?: string
}

export enum NEWSLETTER_STEP {
  SUBSCRIBE = 'subscribe',
  PREFERENCE = 'confirmationPreference',
  THANK_YOU = 'thankYou',
  LOGIN = 'login',
}

export async function useNewsletter() {
  const { openDialog } = useDialog(SIDEBAR_IDS.newsletterSidebar)
  const { emailExists, getSFUserFromEmail } = useAuth0()
  const nuxtApp = useNuxtApp()

  const { isLogged } = useLogin()
  const isLoading = useLoaderState()

  const email = useState<string>('newsletter-email', () => '')
  const salesforceId = useState<string>('newsletter-salesforceId', () => '')
  const step = useState<NEWSLETTER_STEP>(
    'newsletter-step',
    () => NEWSLETTER_STEP.SUBSCRIBE
  )
  const { currentWebsite, isBrand } = useWebsite()
  const config = useAppConfig()
  const country = config.currentCountry as string
  const language = config.currentLanguage
  const { customer } = await nuxtApp.runWithContext(
    async () => await useCustomer()
  )

  const { getBrandAcronym } = await nuxtApp.runWithContext(
    async () => await useBrandsSettings()
  )

  const brandPreference = computed(() => {
    if (isBrand(currentWebsite.value)) {
      return [getBrandAcronym(currentWebsite.value)]
    } else {
      return Array.from(new Set(brands.map(item => getBrandAcronym(item))))
    }
  })

  const clearNewsletterLocalStorage = () => {
    localStorage.removeItem('nl-login-tmp')
  }

  const openNewsletterSidebar = async (email: string) => {
    try {
      clearNewsletterLocalStorage()
      setNewsletterEmail(email)
      if (!isLogged.value) {
        const exists = await emailExists(email)
        if (exists && exists.sfExists && exists.userOrFail) {
          setCurrentStep(NEWSLETTER_STEP.LOGIN)
        } else if (exists && exists.sfExists) {
          //retrive sfId and send update request
          const user = await getSFUserFromEmail(email)
          //this is done for type checking, but since exists.sfExists is true, user should never be false
          if (!user) throw createError({ message: 'User not found' })
          await updateNewsletterUser(email, user.salesforceId!, {
            marketing_authorization: true,
            brands_preferences: brandPreference.value.filter(
              (item): item is string => item !== undefined
            ),
          })
          setCurrentStep(NEWSLETTER_STEP.PREFERENCE)
        } else {
          const createNewsletterUserResponse = await createNewsletterUser(
            email,
            brandPreference.value.filter(
              (item): item is string => item !== undefined
            )
          )
          if (createNewsletterUserResponse) {
            salesforceId.value = createNewsletterUserResponse.sfId
            setCurrentStep(NEWSLETTER_STEP.PREFERENCE)
          }
        }
      } else {
        if (!email) setNewsletterEmail(customer?.value?.email || '')
        setCurrentStep(NEWSLETTER_STEP.PREFERENCE)
      }
      openDialog(SIDEBAR_IDS.newsletterSidebar)
    } catch (error) {
      console.error(error)
    }
  }

  const createNewsletterUser = async (
    email: string,
    brandPreference: string[]
  ) => {
    isLoading.value = true
    try {
      const { salesforceId: sfId } = await $fetch<{ salesforceId: string }>(
        '/api/newsletter/createSfUser',
        {
          method: 'POST',
          body: {
            email: email,
            brandPreference,
            currentCountry: country,
            currentLanguage: language,
          },
        }
      )
      return { sfId }
    } catch (err) {
      // @ts-expect-error
      const message = err?.errors ?? err?.message
      throw createError({ message })
    } finally {
      isLoading.value = false
    }
  }

  const updateNewsletterUser = async (
    email: string,
    salesforceId: string,
    data: NewsletterFormData
  ) => {
    isLoading.value = true
    try {
      return await $fetch('/api/newsletter/updateSfUser', {
        method: 'POST',
        body: {
          email: email,
          sfId: salesforceId,
          data: data,
          language: language,
          country: country,
        },
      })
    } catch (err) {
      // @ts-expect-error
      const message = err?.errors ?? err?.message
      throw createError({ message })
    } finally {
      isLoading.value = false
    }
  }

  const setCurrentStep = (newStep: NEWSLETTER_STEP) => {
    step.value = newStep
  }

  const setNewsletterEmail = (newEmail: string) => {
    email.value = newEmail
  }

  return {
    email,
    salesforceId,
    step,
    createNewsletterUser,
    updateNewsletterUser,
    openNewsletterSidebar,
    setCurrentStep,
    setNewsletterEmail,
    clearNewsletterLocalStorage,
    brandPreference,
  }
}
